import React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "~/shared/utils/utils";

const spinnerVariants = cva("animate-spin border-4 h-6 w-6 rounded-full", {
  variants: {
    variant: {
      white: "border-t-white border-x-white/20 border-b-white/20",
      black: "border-t-grey-700 border-x-grey-700/20 border-b-grey-700/20",
      primary: "border-t-primary-700 border-x-primary-700/20 border-b-primary-700/20",
    },
  },
  defaultVariants: {
    variant: "white",
  },
});

type SpinnerProps = React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof spinnerVariants>;

const Spinner = React.forwardRef<HTMLDivElement, SpinnerProps>(({ className, variant, ...props }, ref) => {
  return <div ref={ref} className={cn(spinnerVariants({ variant }), className)} {...props} />;
});
Spinner.displayName = "Spinner";

export { Spinner };
