import { useCallback, useState } from "react";
import { signIn } from "next-auth/react";

import type { LoginCredentials } from "~/interfaces/auth";

interface UseLogin {
  onSuccess: () => void;
}

const useLogin = ({ onSuccess }: UseLogin) => {
  const [error, setError] = useState<string>();

  const loginWithCredentials = useCallback(
    async (data: LoginCredentials) => {
      setError(undefined);

      const res = await signIn("credentials", { ...data, callbackUrl: "/", redirect: false });

      if (res?.error) {
        return setError(res.error);
      }

      onSuccess();
    },
    [onSuccess]
  );

  const loginWithGoogle = useCallback(async () => {
    setError(undefined);
    const res = await signIn("google", { callbackUrl: "/", redirect: false });

    if (res?.error) {
      return setError(res.error);
    }

    onSuccess();
  }, [onSuccess]);

  return { loginWithCredentials, loginWithGoogle, error };
};

export { useLogin };
