import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { Stars } from "~/assets/icons";
import { cn } from "~/shared/utils/utils";

const inputVariants = cva(
  "flex w-full rounded-2 border border-grey-300 box-border bg-white px-4 py-3 text-md leading-6 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-grey-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      focus: {
        default: "focus-visible:ring-2 focus-visible:ring-offset-2",
        none: "focus-visible:ring-0",
      },
      inputSize: {
        sm: "text-sm py-2 px-2",
        md: "text-md py-3",
        lg: "text-lg py-4",
      },
    },
    defaultVariants: {
      inputSize: "md",
      focus: "default",
    },
  }
);

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  VariantProps<typeof inputVariants> & {
    invalid?: boolean;
    showCharactersLeft?: boolean;
    onActionClick?: () => void;
    actionIcon?: React.ReactNode;
    actionDisabled?: boolean;
    icon?: React.ReactNode;
  };

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type = "text",
      invalid,
      inputSize,
      focus,
      value,
      maxLength,
      showCharactersLeft = false,
      actionIcon = <Stars />,
      actionDisabled = false,
      onActionClick,
      icon,
      ...props
    },
    ref
  ) => {
    const isTextField = type === "text" && typeof value === "string";
    const showCounter = showCharactersLeft && isTextField && maxLength;
    const showAction = Boolean(onActionClick);
    const showIndicators = showCharactersLeft || showAction;

    return (
      <div className="relative flex w-full items-center justify-end">
        {icon && <div className="absolute left-4 text-grey-500">{icon}</div>}
        <input
          type={type}
          className={cn(
            inputVariants({ inputSize, focus }),
            { "text-primary-700 border-primary-700": invalid },
            { "pr-14": showAction || showCounter },
            { "pr-20": showAction && showCounter },
            { "pl-14": icon },
            className
          )}
          maxLength={maxLength}
          value={value}
          ref={ref}
          {...props}
        />

        {showIndicators && (
          <div className="flex gap-2 items-center absolute px-3">
            {showCounter && <span>{maxLength - value.length}</span>}
            {showAction && (
              <span
                onClick={!actionDisabled ? onActionClick : undefined}
                className={cn(
                  "cursor-pointer p-1 transition-colors rounded-full hover:bg-grey-100 active:bg-grey-300",
                  { "opacity-50 cursor-not-allowed": actionDisabled }
                )}
              >
                {actionIcon}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
