import React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "~/shared/utils/utils";

const fadeInVariants = cva("w-full animate-in fade-in", {
  variants: {
    direction: {
      "bottom-to-top-5": "slide-in-from-bottom-5",
      "left-to-right-5": "slide-in-from-left-5",
      "right-to-left-5": "slide-in-from-right-5",
      "top-to-bottom-5": "slide-in-from-top-5",
    },
  },
  defaultVariants: {
    direction: "bottom-to-top-5",
  },
});

type Props = VariantProps<typeof fadeInVariants> & {
  className?: string;
  children?: React.ReactNode;
};

export const FadeInContainer: React.FC<Props> = ({ children, className, direction }) => {
  return <div className={cn(fadeInVariants({ direction }), className)}>{children}</div>;
};
